/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --navtext: #f8f9fa;
  --bg: #D3D3D3;
  --hover: #bcbcbc;
  --sidebg: #333333;
  --error: #e7195a;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--bg);
  margin: 0;
  font-family: "Poppins";
  overflow: auto;
}

/* ------------------------- Side Nav ----------------------- */
.sidenav {
  height: auto;       /* Full-height: remove this if you want "auto" height */
  width: 250px;       /* Set the width of the sidebar */
  position: fixed;    /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1;         /* Stay on top */
  top: 0;             /* Stay at the top */
  left: 0;
  background-color: var(--sidebg);
  overflow-x: hidden; /* Disable horizontal scroll */
  margin: 15px 0px 0px 15px;
  box-shadow: 3px 0px 4px black;
  border-radius: 2%;
  color: var(--navtext);
} 

  .sidenav .container {
    position: relative;
    margin: 20px 0px 10px 0px;
    text-align: center;
  }

  /* Side Nav pfp */
  .sidenav .pfp {
    border-radius: 50%;
    height: 200px;
  }

  /* Side Nav Row Icons */
  .sidenav .container .icon {
    height: 30px;
    width: 30px;
    margin: 2.5px 2.5px;
    fill: currentColor;
  }

  /* Side Nav Divider */
  .sidenav hr {
    margin: 30px 10px 30px 10px;
    border-top: 1.5px solid var(--bg);
    border-radius: 5%;
  }

  /* Side Nav Links*/

  .sidenav ul {
    padding: 0px 0px 0px 10px;
    margin: 0px;
  }

    .sidenav ul .icon {
      height: 27px;
      width: 27px;
      fill: currentColor;
      display: inline;
      margin: auto 10px;
    }

  .sidenav li {
    list-style-type: none;
    margin: 10px 0px 10px 0px;
  }
    .nav-link {
      padding: 1px 8px 1px 16px;
      margin: 10px 0px 10px 0px;
      display: flex;
    }

    .nav-link:hover {
      cursor: pointer;
      color: var(--hover);
    }

    .sidenav li a {
      padding: 1px 8px 1px 16px;
      margin: 10px 0px 10px 0px;
      display: flex;
    }

  .sidenav h1 {
    font-size: 22px;
    margin: 0px;
  }

  .sidenav a {
    color: var(--navtext);
    text-decoration: none;
  }
  .sidenav a:hover {
    color: var(--hover);
  }

  .sidenav button {
    background-color: transparent;
    border: 1px solid var(--navtext);
    cursor: pointer;
    color: var(--navtext);
    font-size: 14px;
    padding: 8px 18px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5%;
    margin: 0px 30px 10px 30px;
  }

/* Pages */
.pages {
  margin-left: 265px;   /* Same as the width of the sidebar + margin */
  padding: 0px 10px;
  color: var(--sidebg);
}

  .pages .header {
    font-size: 30px;
    margin: 30px auto;
    text-align: center;
    text-decoration: underline;
  }

/* About Page */
.aboutPage {

}

  .aboutPage p {
    font-size: 18pt;
    text-align: justify;
    margin: 50px;
  }

/* Project Page */
.projectsBody {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

  .projectsBody .loading {
    font-size: 40px;
    margin: auto 0px;
    text-align: center;
  }

.projectCard {
  background-color: var(--sidebg);
  margin: 25px;
  padding: 5px;
  perspective: 1000px; /* For 3D effect */
  box-shadow: 3px 0px 4px black;
  transition: height 0.8s, max-height 0.8s;
  /* height set in ProjectCart.js */
}

  .innerProjectCard {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  .projectCard:hover .innerProjectCard {
    transform: rotateY(180deg);
  }

/* Position the front and back side */
.projectCardFront, .projectCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side */
.projectCardFront {
  background-color: var(--sidebg);
  display: flex;
  color: var(--navtext);

}
  .projectCardFront img {
    width: 35%;
    height: 85%;
    margin: auto 2%;
    border-radius: 2%;
  }
  .projectCardFront h1 {
    display:inline-block; 
    padding: 10px;
    margin: auto 0px;
    text-align: center;
    font-size: 1.5vw;
  }
/* Style the back side */
.projectCardBack {
  background-color: var(--sidebg);
  color: var(--navtext);
  transform: rotateY(180deg);
}

  .projectCardBack .iconsList {
    display: flex;
    justify-content: center;
  }
  .projectCardBack .icon {
    height: 32px;
    width: 32px;
    fill: currentColor;
    color: var(--navtext);
    margin: 5px;
  }

  .projectCardBack .icon:hover {
    color: var(--hover);
    cursor: pointer;
  }

  .projectCardBack p {
    width: 85%;
    margin: 5% auto 2%;
    text-align: center;
    font-size: 1vw;
  }

  .addNewProjectCard {
  margin: 50px;
  padding: 5px;
  background-color: var(--sidebg);
  color: var(--navtext);
  box-shadow: 3px 0px 4px black;
  border-radius: 20px;
  }

    .addNewProjectCard h1 {
      width: 100%;
      padding: 10px;
      margin: auto 0px;
      text-align: center;
    }

    .addNewProjectCard form {
      margin: 20px;
    }

    .addNewProjectCard input {
      box-sizing: border-box;
      outline: none;
      padding: 7px;
      border: none;
      border-bottom: 1px solid var(--navtext);
      background: transparent;
      margin-bottom: 10px;
      font: 16px Arial, Helvetica, sans-serif;
      height: 45px;
      color: var(--navtext)
    }

    .addNewProjectCard .topRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2%;
    }

    .addNewProjectCard .full {
      width: 100%;
    }

    .addNewProjectCard button {
      background-color: transparent;
      border: 1px solid var(--navtext);
      display: inline-block;
      cursor: pointer;
      color: var(--navtext);
      font-size: 14px;
      padding: 8px 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-radius: 5%;
      margin-top: 20px;
    }

    .addNewProjectCard button:hover {
      /* background:linear-gradient(to bottom, #34CACA 5%, #30C9C9 100%); */
      border: 1px solid var(--hover);
      color: var(--hover);
    }

    .addNewProjectCard .error {
      border-color: var(--error);
    }
    .addNewProjectCard .error::placeholder {
      color: var(--error);
    }

  .editProjectCard {
    margin: 50px;
    padding: 5px;
    background-color: var(--sidebg);
    color: var(--navtext);
    box-shadow: 3px 0px 4px black;
    border-radius: 20px;
  }

    .editProjectCard h1 {
      width: 100%;
      padding: 10px;
      margin: auto 0px;
      text-align: center;
    }

    .editProjectCard form {
      margin: 20px;
    }

    .editProjectCard input {
      box-sizing: border-box;
      outline: none;
      padding: 7px;
      border: none;
      border-bottom: 1px solid var(--navtext);
      background: transparent;
      margin-bottom: 10px;
      font: 16px Arial, Helvetica, sans-serif;
      height: 45px;
      color: var(--navtext)
    }

    .editProjectCard .topRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2%;
    }

    .editProjectCard .full {
      width: 100%;
    }

    .editProjectCard form button {
      background-color: transparent;
      border: 1px solid var(--navtext);
      display: inline-block;
      cursor: pointer;
      color: var(--navtext);
      font-size: 14px;
      padding: 8px 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-radius: 5%;
      margin-top: 20px;
      margin-right: 20px;
    }

    .editProjectCard button:hover {
      /* background:linear-gradient(to bottom, #34CACA 5%, #30C9C9 100%); */
      border: 1px solid var(--hover);
      color: var(--hover);
    }

    .editProjectCard .error {
      border-color: var(--error);
    }
    .editProjectCard .error::placeholder {
      color: var(--error);
    }



.loginCard {
  margin: 50px;
  padding: 5px;
  background-color: var(--sidebg);
  color: var(--navtext);
  box-shadow: 3px 0px 4px black;
  border-radius: 20px;
  }

    .loginCard h1 {
      width: 100%;
      padding: 10px;
      margin: 10px 0px;
    }

    .loginCard form {
      margin: 20px;
    }

    .loginCard input {
      box-sizing: border-box;
      outline: none;
      padding: 7px;
      border: none;
      border-bottom: 1px solid var(--navtext);
      background: transparent;
      margin-bottom: 10px;
      font: 16px Arial, Helvetica, sans-serif;
      height: 45px;
      color: var(--navtext)
    }

    .loginCard .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2%;
    }

    .loginCard button {
      background-color: transparent;
      border: 1px solid var(--navtext);
      display: inline-block;
      cursor: pointer;
      color: var(--navtext);
      font-size: 14px;
      padding: 8px 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-radius: 5%;
      margin-top: 20px;
      margin-right: 20px;
    }

    .loginCard button:hover {
      /* background:linear-gradient(to bottom, #34CACA 5%, #30C9C9 100%); */
      border: 1px solid var(--hover);
      color: var(--hover);
    }

    .loginCard .error {
      border-color: var(--error);
      margin-top: 10px;
    }
    .loginCard .error::placeholder {
      color: var(--error);
    }

.showLoginCard {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
.showLoginCard button {
  background-color: var(--sidebg);
  border: 1px solid var(--sidebg);
  cursor: pointer;
  color: var(--navtext);
  font-size: 14px;
  padding: 8px 18px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5%;
  margin: 20px;
}

.pages button:hover {
  /* background:linear-gradient(to bottom, #34CACA 5%, #30C9C9 100%); */
  border: 1px solid var(--hover);
  color: var(--hover);
}

@media screen and (max-width: 700px) {

  .sidenav {
    width: 80%;       /* Set the width of the sidebar */
    margin: 10% 10% 10% 10%;
  } 
  .sidenav a:hover {
    color: var(--navtext);
  }

}

a, a:link, a:visited, a:focus, a:hover, a:active{
  text-decoration:none;
}